var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass: "payment",
      class: { "w-90": _vm.$vuetify.breakpoint.lgAndUp }
    },
    [
      _c(
        "div",
        { staticClass: "checkout-title" },
        [
          _c("CategoryTitle", {
            attrs: {
              category: _vm.category,
              showOnMobile: true,
              centered: _vm.$vuetify.breakpoint.xs,
              showDescription: false
            }
          })
        ],
        1
      ),
      _c(
        "v-container",
        { staticClass: "pa-0" },
        [
          _c(
            "v-row",
            [
              _vm.categoryDescription
                ? _c(
                    "v-col",
                    {
                      staticClass: "categoryDescription mt-3 px-0 pl-md-3",
                      attrs: { cols: "12", order: "1" }
                    },
                    [
                      _c("div", {
                        staticClass: "content",
                        domProps: { innerHTML: _vm._s(_vm.categoryDescription) }
                      })
                    ]
                  )
                : _vm._e(),
              _c(
                "v-col",
                {
                  staticClass: "pt-0",
                  attrs: {
                    cols: "12",
                    md: "3",
                    lg: "4",
                    order: "1",
                    "order-md": "2"
                  }
                },
                [
                  _c("div", { staticClass: "mb-3 px-3 px-sm-0" }, [
                    _c(
                      "div",
                      { staticClass: "mb-3" },
                      [
                        _c("CartInfoAddressCard", {
                          staticClass: "w-100",
                          attrs: {
                            shippingAddress: _vm.cart.shippingAddress,
                            editable: false,
                            isCheckout: true
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("CartInfoTimeslotCard", {
                          staticClass: "w-100 cart-info-timeslot-card",
                          attrs: {
                            shippingAddress: _vm.cart.shippingAddress,
                            timeslot: _vm.cart.timeslot,
                            editable: false,
                            isCheckout: true,
                            value: _vm.timeslot
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("CheckoutSummary", {
                    staticClass: "accent mb-6 mx-3 mx-sm-0",
                    attrs: { orderCart: _vm.cart }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "pt-0",
                  attrs: {
                    cols: "12",
                    md: "9",
                    lg: "8",
                    order: "2",
                    "order-md": "1"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "mb-3 px-3 px-sm-0" },
                    [
                      _c(
                        "h4",
                        { staticClass: "secondary--text text-uppercase" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("paymentInvoice.billingInformation")
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "div",
                        [
                          _c("label", {
                            staticClass: "font-weight-bold",
                            domProps: {
                              innerHTML: _vm._s(
                                `${_vm.$t("paymentInvoice.company")}`
                              )
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              flat: "",
                              "hide-details": "",
                              disabled: ""
                            },
                            model: {
                              value: _vm.user.person.company,
                              callback: function($$v) {
                                _vm.$set(_vm.user.person, "company", $$v)
                              },
                              expression: "user.person.company"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "pt-2" },
                        [
                          _c("label", {
                            staticClass: "font-weight-bold",
                            domProps: {
                              innerHTML: _vm._s(
                                `${_vm.$t("paymentInvoice.vatCode")}`
                              )
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              flat: "",
                              "hide-details": "",
                              disabled: ""
                            },
                            model: {
                              value: _vm.user.person.vatCode,
                              callback: function($$v) {
                                _vm.$set(_vm.user.person, "vatCode", $$v)
                              },
                              expression: "user.person.vatCode"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "pt-2" },
                        [
                          _c("label", {
                            staticClass: "font-weight-bold",
                            domProps: {
                              innerHTML: _vm._s(
                                `${_vm.$t("paymentInvoice.address")}`
                              )
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              value: _vm.fullAddress,
                              dense: "",
                              outlined: "",
                              flat: "",
                              "hide-details": "",
                              disabled: ""
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-divider", { staticClass: "mt-8 mb-7" }),
                      _c(
                        "div",
                        [
                          _c(
                            "v-form",
                            {
                              ref: "secondaryemailform",
                              model: {
                                value: _vm.isSecondaryEmailFormValid,
                                callback: function($$v) {
                                  _vm.isSecondaryEmailFormValid = $$v
                                },
                                expression: "isSecondaryEmailFormValid"
                              }
                            },
                            [
                              _c(
                                "h4",
                                {
                                  staticClass: "secondary--text text-uppercase"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "paymentInvoice.addSecondaryEmail"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c("v-text-field", {
                                attrs: {
                                  placeholder: `${_vm.$t(
                                    "paymentInvoice.secondaryEmail"
                                  )}`,
                                  outlined: "",
                                  dense: "",
                                  rules: _vm.emailRules
                                },
                                model: {
                                  value: _vm.newSecondaryEmail,
                                  callback: function($$v) {
                                    _vm.newSecondaryEmail = $$v
                                  },
                                  expression: "newSecondaryEmail"
                                }
                              }),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    depressed: "",
                                    disabled: !_vm.isSecondaryEmailFormValid,
                                    loading: _vm.loadingSecondaryEmailChange
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.updateSecondaryEmail()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("profile.save")) + " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-divider", { staticClass: "mt-8 mb-7" }),
                      _c(
                        "h4",
                        { staticClass: "secondary--text text-uppercase" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.cart.cartStatusId == 7
                                  ? _vm.$t("paymentInvoice.paymentMethodChosen")
                                  : _vm.$t("paymentInvoice.choosePaymentMethod")
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c("p", [
                        _c("strong", [
                          _vm._v(
                            _vm._s(_vm.$t("paymentInvoice.orderObligation"))
                          )
                        ])
                      ]),
                      _c("PaymentTypeList", {
                        staticClass: "mx-3 mx-sm-0",
                        attrs: {
                          options: {
                            showRememberCard: false,
                            showPaymentMethodImage: false,
                            showCardLogos: true,
                            orderAmount: _vm.cart.totalPrice,
                            showPrebilledAmountInfoText: true,
                            mainBackgroundColor: "transparent",
                            confirm: { color: "primary" },
                            back: {
                              outline: true,
                              class: "secondary--text"
                            },
                            titleStyle:
                              "secondary--text text--darken-2 text-body-1 mb-2"
                          },
                          isPaymentDisabled: !_vm.billingFormCompletedInternal,
                          paymentNameToPaymentInfo: true
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }